.rich-input-wrapper {
    max-width: 800px;
    width:100%;
    display: block;
}

.rich-input-editor {
    padding: 6px 5px 0;
    border-radius: 5px;
    border: 1px solid #F1F1F1;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    -webkit-user-select: none;
    user-select: none;

    min-height: 200px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    cursor: text;
}

.rich-input-editor:focus-within {
    outline: 4px solid #f6e200;
    border: 2px solid black;
    padding-left: 7px;
    border-radius: 0px;
}