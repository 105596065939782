body {
  margin: 0;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(15, 20, 25)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rich-text-editor {
  font-family:'Avenir', -apple-system, 'Arial', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

.rich-text-editor:focus {
  border: 1px solid #009cde;
}

.rich-input-editor {
  cursor:default;
  box-shadow: rgb(232 232 233) 15px 4px 150px;
}

.DraftEditor-editorContainer {
  width:100%;
  height:100%;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./assets/fonts/AvenirLTStd-Black.otf) format('opentype');
  font-weight: 700;
  font-style: bold;
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./assets/fonts/AvenirLTStd-Medium.otf) format('opentype');
  font-weight: 500;
  font-style: normal;
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./assets/fonts/AvenirLTStd-Book.otf) format('opentype');
  font-weight: 400;
  font-style: normal;
  /* other formats include: 'woff2', 'truetype, 'opentype',                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./assets/fonts/AvenirLTStd-Light.otf) format('opentype');
  font-weight: 300;
  font-style: normal;
  /* other formats include: 'woff2', 'truetype, 'opentype',                            'embedded-opentype', and 'svg' */
}

.gridImage {
  width:100%;
  border-radius:10px;
  height:100%;
  max-height: 200px !important;
  object-fit: cover;
}

.activityDesc a {
  color: #0067c6;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}