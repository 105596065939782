   .mentions {
    margin: 1em 0;
    margin-top: 0px;
  }

  .mentions--singleLine .mentions__control {
    display: inline-block;
  }
  .mentions--singleLine .mentions__higlighter {
    padding: 1px;
    border: 2px inset transparent;
  }
  .mentions--singleLine .mentions__input {
    padding: 0px;
    border: 2px inset;
  }

  .mentions--multiLine .mentions__control {
    /*font-family: monospace;*/
    /*font-size: 14pt;*/
    min-height: 20px;
    /* border: 1px solid silver; */
  }
  .mentions--multiLine .mentions__highlighter {
    padding: 9px;
  }
  .mentions--multiLine .mentions__input {
    padding: 9px;
    /*min-height: 63px;*/
    min-height: 20px;
    outline: 0;
    border: 0;
  }

  .mentions__suggestions__list {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 10pt;
    max-height: 100px;
    overflow-y: auto;
  }

  .mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .mentions__suggestions__item--focused {
    background-color: #cee4e5;
  }

  .mentions__mention {
    background-color: #cee4e5;
  }




  .activityImage {
    position: relative;
  }

  .activityImage .imageDelete {
    background-color: red;
    width: 20px;
    color: white;
    display: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
  }

  .activityImage:hover .imageDelete {
    display: block;
  }

  .activityImage .smallImage {
    width: auto;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-bottom: 10px;
  }

  .modal-image {
    width: auto;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-bottom: 10px;
  }